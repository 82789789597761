.protocols {
  table {
    border: $gray-500 1px solid;
    margin-top: 20px;
    font-size: 15px;

    thead {
      background-color: $gray-200;
    }

    td,
    th {
      border: $gray-500 1px solid;
      vertical-align: top;
      padding: 10px;
      .points {
        margin: 0;
      }
      .judge-code {
        min-width: 45px;
      }
      .panel-name {
        font-weight: 200;
        font-size: 14px;
        color: $gray-900;
      }
      .total-points {
        padding-top: 5px;
        font-weight: 600;
        text-align: center;
      }

      .sub-total-points {
        font-weight: 600;
      }

      .points-group:not(:first-child) {
        padding-top: 5px;
      }

      &.last-group-cell {
        border-right: $gray-500 2px solid;
      }
    }
  }

  .points-table {
    width: 100%;
    min-width: 690px;
    table-layout: fixed;
    td {
      &.points {
        font-size: 14px;
      }
    }

    &__agg {
      td {
        &.points {
          padding-right: 5px;
          padding-left: 5px;
        }
      }
    }

    &__rg,
    &__agg,
    &__akro,
    &__tanvo,
    &__lumo {
      th,
      td {
        &.points {
          width: 155px;
        }
        &.total-points {
          width: 125px;
          vertical-align: top;
        }
        &.place {
          width: 45px;
          vertical-align: top;
        }
        &.panel {
          width: 170px;
        }
      }
      &__fixed {
        th,
        td {
          &.points {
            width: 300px;
          }
        }
        .points-block {
          margin-bottom: 8px;
        }
      }
      &__tul {
        th {
          &.points {
            width: 125px;
          }
        }
      }
    }

    &__agg {
      th,
      td {
        &.partial-points {
          width: 110px;
        }
        &.partial-points-2x {
          width: 220px;
        }
        &.total-points {
          width: 115px;
        }
      }
    }

    &__akro {
      min-width: 1020px;
    }
    &__tanvo,
    &__akro,
    &__lumo {
      height: 10%; /* // needed to make div fill the entire cell*/
      th.participant {
        width: 200px;
      }
      th.points {
        width: 300px;
      }
    }
    &__lumo {
      th.points {
        width: 200px;
      }
    }
  }

  .judge-table {
    min-width: 50%;
  }

  .judge-panel-details {
    margin-top: 30px;
  }

  @media print {
    .no-print {
      display: none;
    }

    .points-table {
      &__lumo {
        th {
          &.total-points {
            width: 100px;
            font-size: 14px;
          }
          &.points {
            width: 170px;
          }
        }
      }
      tbody {
        font-size: 14px;
      }
    }

    /*        @page  {
            size: A4;
        }*/
  }

  .multi-round-protocol {
    &__participant-column {
      min-width: 320px;
      width: 550px;
      max-width: 550px;
    }

    &__participant-name {
      font-weight: 600;
    }

    &__points-column {
      min-width: 60px;
    }
  }
}

.akro-protocol {
  &__participant-col {
    width: 200px;
  }

  &__program-col {
    width: 360px;
  }

  &__combined-program-col {
    width: 200px;
  }

  &__total-points-col {
    width: 115px;
  }
}
