ngb-timepicker.start-list-table__entry-timepicker {
  .ngb-tp-spacer {
    width: 0.3em;
  }
  .ngb-tp-input-container {
    width: 2em;
    input.ngb-tp-input.form-control {
      padding: 0;
      width: 2em;
    }
  }
}

.start-list-table {
  &__dnd-chosen {
    min-height: 150px;
    background-color: #dcacff !important;
  }
  &__dnd-drag {
    min-height: 150px;
    background-color: #1b1b75 !important;
  }
}
