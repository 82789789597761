.event-competition {
  &__main {
    margin-top: 32px;
  }

  &__create-form {
    margin-top: 40px;
  }

  .btn-tabs {
    margin-top: 40px;

    &__inner {
      margin-bottom: 0;
    }

    &__tab button {
      font-size: 24px;
      text-transform: uppercase;
    }
  }

  .performing {
    color: $blue;
    font-weight: bold;
  }
  .performance-done {
    color: $gray-700;
  }
  .disabled {
    color: $gray-700;
    background-color: $gray-400 !important;
  }
}

.event-competition-ctrls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 40px;

  &__left-part {
    display: flex;
  }

  &__right-part {
    .btn {
      min-width: 182px;
    }
  }

  &__dropdown {
    min-width: 182px;
    margin-right: 16px;
  }

  .btn {
    margin-right: 16px;
  }
}

.comp-startlist {
  .nav-tabs {
    margin-bottom: 16px;
    flex-wrap: wrap;

    .nav-link {
      font-size: 18px;
      text-transform: initial;
    }
  }

  &__table {
    position: relative;

    th,
    td {
      text-align: left;
      &.startTime,
      &.orderNumber {
        width: 1%;
        padding-right: 8px;
      }

      &.startTime {
        padding-left: 8px;
      }

      &.actions-1 {
        width: 2%;
      }

      &.actions,
      &.panel {
        width: 5%;
      }

      &.programType {
        width: 10%;
      }

      &.club,
      &.participant {
        width: 12%;
      }

      &.category {
        width: 15%;
      }
    }

    tr,
    tr:nth-child(even) {
      background-color: $white;
    }

    tr.ui-draggable-dragging {
      width: 100% !important;
      position: absolute !important;
      background-color: $gray-200;
      opacity: 0.8;
      button {
        display: none;
      }
    }
  }

  &__table-actions {
    display: flex;
  }

  &__table-action {
    &:hover {
      cursor: pointer;
    }

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &.ag-start-list {
    .sub-group-title {
      margin-top: 30px;
    }

    .orderNumber {
      width: 7%;
    }

    .club {
      width: 100%;
    }
    .participant {
      min-width: 300px;
    }
  }

  &__start-list-selector {
    .ng-dropdown-panel {
      width: auto !important;
      min-width: 400px;
      max-width: 800px;
    }
  }
}

.trackbar {
  border: none !important;
  padding: 0 20px !important;
}

.comp-create {
  margin-bottom: 40px;

  .group-info {
    color: $gray-900;
    font-weight: 500;
    font-size: 16px;
  }

  .apparatus-item {
    padding: 10px;
    background-color: $sky-blue;
    margin-left: 5px;
    margin-right: 5px;
  }

  &-summary {
    margin-bottom: 49px;

    &__heading {
      margin-bottom: 16px;
      text-transform: uppercase;
    }

    &__list {
      font-weight: bold;
    }

    &__list-item {
      display: flex;
      margin-bottom: 16px;
      div:nth-child(1) {
        margin-right: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .divider {
    margin: 16px 0;
    height: 1px;
    background-color: $gray-400;
  }

  &-blocks {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-block {
    padding: 16px;
    margin-bottom: 8px;
    background-color: $gray-300;

    &__panel {
      margin-bottom: 16px !important;
    }

    &__fields {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      &-divider {
        margin-right: 16px;
      }
    }

    &__input {
      width: 128px;

      &--lg {
        width: 241px;
      }

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__time {
      width: 90px;

      &:not(:last-child) {
        margin-right: 16px;
      }
    }

    &__button {
      span {
        color: $primary;
      }
      svg,
      path {
        fill: $primary;
      }

      &--blue {
        span {
          color: $blue;
        }
        svg,
        path {
          fill: $blue;
        }
      }

      &--red {
        span {
          color: $red;
        }
      }
    }
  }

  &-panel {
    margin-bottom: 8px;

    //&__header {
    //  display: flex;
    //  align-items: center;
    //  margin-bottom: 16px;
    //}

    &__heading {
      margin-right: 16px;
      margin-bottom: 0;
    }

    &__field {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    &__input {
      width: 170px;
      margin-right: 16px;
      &--select {
        width: 438px;
      }
    }

    &__delete-icon-enabled {
      cursor: pointer;
    }

    &__delete-icon-disabled {
      svg {
        path {
          fill: $gray-500;
        }
      }
    }
  }
}

.judge-point-control {
  display: inline-flex;
  > div {
    padding-left: 8px;
  }

  .label {
    margin-bottom: auto;
    margin-top: auto;
  }
}
