html,
body {
  min-height: 100%;
  height: 100%;
}

#body {
  min-height: calc(100% - 60px); /* 60px - header height */
  position: relative;
  padding-bottom: 190px; /*// space for footer*/
}

footer {
  position: absolute;
  bottom: 0;
  height: 84px;
  width: 100%;
  background: #fff;
}

.content-section {
  padding-top: 40px;

  &--no-extra-space {
    margin-top: 0;

    header {
      padding-top: 0;
    }
  }

  &__header {
    padding: 16px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid $gray-400;

    &--top {
      display: flex;
      justify-content: space-between;
    }
  }

  &__heading {
    font-size: 30px;
    font-weight: bold;
    color: $primary;
  }

  &__main {
    margin-top: 40px;
  }

  .nav-tabs .nav-link {
    font-size: 18px;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-margin {
  margin-left: 0;
  margin-right: 0;
}
