.promo-block {
  &__carousel,
  .carousel-inner {
    width: 100%;
    height: 480px;

    @include media-breakpoint-down('xs') {
      height: 160px;
    }
  }

  &__inner {
    height: 480px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    @include media-breakpoint-down('xs') {
      height: 160px;
    }
  }
}
