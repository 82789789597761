.ad-banner-multi-editor {
  .nav-tabs {
    flex-wrap: wrap;
    justify-content: center;

    .nav-link {
      font-size: 18px;
      text-transform: initial;
    }
  }
}

.ad-banner-editor {
  min-height: 100px;
  background-color: #f1efef;

  &__img {
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    cursor: crosshair;
    border: #0e316d 1px solid;
  }

  &__img-container {
    position: relative;
  }

  &__progress-bar {
    width: 500px;
  }

  &__info-text {
    font-size: 14px;
  }

  &__info-text-internal {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
}

.ad-banner-editor-click-area {
  position: absolute;
  background-color: rgba(59, 132, 232, 0.8);
  border: #2a2adb 3px solid;
  color: #fff;

  &__inner {
    width: 100%;
    margin-left: 7px;
    margin-right: -7px;
    z-index: 100;
  }

  &:hover {
    background-color: rgba(9, 42, 91, 0.8);
    .ad-banner-editor-click-area__delete-button {
      display: block;
    }
  }

  &__handle {
    background-color: #fff;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    position: absolute;
    border: blue 1px solid;

    &:hover {
      background-color: #a0a0a0;
    }

    &--top {
      top: -7px;
      left: -7px;
    }
    &--bottom {
      bottom: -7px;
      right: -7px;
    }
  }

  &__delete-button {
    background-color: #fff;
    border-radius: 5px;
    right: 0;
    top: 0;
    width: 25px;
    height: 25px;
    position: absolute;
    display: none;
    border: gray 1px solid;

    button {
      padding: 0 3px;
      margin-top: -5px;
    }
  }

  &__delete-button-container {
    position: absolute;
    width: 100%;
    height: 100%;
    right: -25px;
    top: -25px;
    min-height: 25px;
    min-width: 25px;
  }

  &__url {
    color: #fff;
    font-weight: 600;
    margin-top: auto;
    margin-bottom: auto;
    overflow-x: hidden;
    padding: 0 7px;
    z-index: 100;
    line-height: 20px;

    &:hover {
      overflow-x: visible;
      background-color: rgba(9, 42, 91, 0.8);
      cursor: pointer;
    }

    &--editing {
      overflow-x: visible;
      background-color: rgba(9, 42, 91, 0.8);
      padding: 5px 7px;
    }

    input,
    button {
      z-index: 100;
    }
  }

  &__save-button {
    svg path {
      fill: #fff;
    }
  }
}
