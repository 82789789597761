.cookie {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 40px 0;
  background-color: rgba(0, 48, 82, 0.9);

  color: $white;

  &__row {
    align-items: center;
  }

  &__heading {
    margin-bottom: 16px;
  }

  &__text {
    margin: 0;
    font-size: 14px;
  }

  &__button {
    width: 100%;
    background-color: $white;
    color: $primary;
    box-shadow: none;
    border-radius: 4px;
    padding: 8px;
    text-align: center;

    @include media-breakpoint-down('md') {
      margin-top: 16px;
    }
  }
}
