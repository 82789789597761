.alert {
  border: none;

  &-danger {
    color: $white;
    background-color: $red;
  }

  &-warning {
    color: #730707;
    background-color: $yellow;
  }
}
