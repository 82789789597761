.reports-component {
  .date-range-bar {
    > div:not(:last-child) {
      padding-right: 15px;
    }
  }
}

.reports {
  &__event-name-col {
    width: 300px;
  }
  &__organizer-col {
    width: 250px;
  }
  &__date-col {
    width: 100px;
  }
  &__sport-col {
    width: 150px;
  }
  &__reg-count-col {
    width: 100px;
  }
  &__city-col {
    width: 150px;
  }
}
