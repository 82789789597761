.event-application {
  textarea {
    height: 150px;
    resize: none;
  }

  button.btn {
    width: 182px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  .label {
    color: $gray-500;
  }

  .custom-radio:not(:last-child) {
    margin-right: 18px;
  }

  .radio-buttons {
    display: flex;
    align-items: center;
  }

  .centered {
    height: 100%;
    align-items: center;
  }

  .form-group label {
    font-size: inherit;
  }

  @include media-breakpoint-down('md') {
    .create-new-form {
      padding: 16px;
    }
  }

  @include media-breakpoint-down('md') {
    .form-group .col-md-6:not(:last-child) {
      margin-bottom: 1rem;
    }

    .content-section__main {
      margin-top: 0px;
    }
  }

  @include media-breakpoint-down('xs') {
    button.btn {
      width: calc(50% - 10px);
    }
  }
}
