.event-details {
  .start-list__block-tabset {
    .nav-tabs {
      flex-wrap: wrap;
    }
  }
}

.start-list__block-tabset {
  .nav-tabs {
    flex-wrap: wrap;
  }
}

.start-list-warning {
  &__button {
    position: absolute;
    top: 0;
    right: 8px;

    svg {
      path {
        fill: $gray-800;
      }
    }
  }
}
