.judges-card {
  display: block;
  padding: 16px;
  margin-bottom: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;

    h3,
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }

  &__heading {
    margin-bottom: 0;
    color: $body-color;
  }

  &__class {
    color: $blue;
  }

  &__text {
    font-size: 12px;
    color: $gray-500;
  }
}

th.event-judges,
td.event-judges {
  &__judge-class-col {
    width: 60px;
    padding-left: 2px;
    padding-right: 2px;
  }
}

.event-judges {
  &__registration-date-col {
    width: 120px;
  }

  &__days-categories-col,
  &__food-col {
    width: 200px;
  }

  &__name-col {
    width: 190px;
  }

  &__club-col {
    width: 140px;
  }
  &__sport-col {
    width: 100px;
  }

  &__action-col {
    width: 90px;
  }

  &__table {
    table-layout: fixed;
    min-width: 1200px;

    th,
    td {
      text-align: left;
      vertical-align: unset !important;
      border-top: none;
    }

    td.event-judges__action-col {
      padding-left: 0;
      padding-right: 0;
    }

    &__clipboard {
      cursor: pointer;
    }

    &__flex {
      display: flex;
    }

    .blocked-item {
      color: $red;
    }

    .selected-item {
      color: $green;
    }
  }
}

.reg-judges-form {
  margin-top: 40px;

  &__row {
    margin-bottom: 16px;
  }

  &__textarea {
    min-height: 153px;
    resize: none;
  }

  &__footer {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $gray-400;

    .btn {
      min-width: 182px;
      margin-right: 16px;

      @include media-breakpoint-down('xs') {
        margin-bottom: 16px;
      }
    }
  }

  .block-field:not(:last-child) {
    margin-bottom: 16px;
  }

  &__validation-error {
    color: $red;
  }

  &__select-all-btn {
    font-size: 12px;
  }
}
