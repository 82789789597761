.results {
  &__popover-toggle {
    text-decoration: underline;
    cursor: pointer;
  }
}

@keyframes fadeInBackground {
  0% {
    background-color: inherit;
  }
  10% {
    background-color: #d2fbd2;
  }
  20% {
    background-color: #d2fbd2;
  }
  100% {
    background-color: #e3ffdf;
  }
}

@keyframes fadeOutBackground {
  from {
    background-color: #e3ffdf;
  }
  to {
    background-color: inherit;
  }
}

.results-table__latest-result {
  &.results-table--fade-in-background {
    animation: fadeInBackground 3s forwards;
  }

  &.results-table--fade-out-background {
    animation: fadeOutBackground 1.2s forwards;
  }
}
