.event-dropdown {
  width: 47px;

  .default-dropdown__toggle {
    min-width: 47px;
    &:after {
      content: none;
    }
  }
}
