.start-list-group {
  padding: 18px 16px 8px 16px;
  font-size: 14px;
  background-color: $white;
  position: relative;

  &__header {
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 18px;
  }

  &__member-item {
    padding: 4px 16px;
    background-color: $gray-400;
    margin-bottom: 8px;
    position: relative;

    &_cancelled {
      text-decoration: line-through;
    }
  }

  &__group-registrations {
    height: calc(100% - 245px);
  }

  &__delete-button {
    position: absolute;
    top: -1px;
    right: 3px;
  }
}
