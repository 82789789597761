h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  line-height: 120%;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
  font-weight: normal;
}
