.planned-event {
  &__selecting-actions {
    display: flex;
    align-items: center;
  }

  &__selecting-action {
    min-width: 166px;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  &__select-sport {
    min-width: 250px;
  }

  .btn_right {
    text-align: right;
  }

  &__create-event {
    .planned-event-form {
      padding: 0;
    }
  }

  .event-description {
    color: $gray-800;
    text-transform: none;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }
}

.card-event {
  &__members {
    line-height: 110%;
  }

  &__info-item {
    .granted {
      color: #57b5dc;
      font-weight: 600;
    }
  }
}

.planned-event-form {
  padding: 40px;
  @include media-breakpoint-down('xs') {
    padding: 40px 8px;
  }

  &__name {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
  }

  &__event-data {
    align-items: center;
  }

  &__section {
    margin-top: 40px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-400;

    h5 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__add-item {
    display: flex;
    align-items: center;

    .default-dropdown {
      width: 100%;
    }
  }

  &__add-new {
    display: flex;
    align-items: center;
    color: $blue;
  }

  &__remove-icon {
    &:hover {
      cursor: pointer;
    }
  }

  &__buttons {
    .btn {
      min-width: 182px;
    }
  }
}

.planned-groups {
  h3 {
    color: $gray-700;
    margin-bottom: 16px;
    text-transform: uppercase;

    &.modal-title {
      text-transform: none;
    }
  }

  &__event .planned-event-form {
    padding: 0;
    box-shadow: none;
    border: none;
  }

  .modal-container {
    position: absolute;
  }
}

.planned-event-detail {
  &__empty {
    @include media-breakpoint-down('md') {
      margin-top: 40px;
    }
  }

  .user_item {
    .label,
    .value {
      padding-left: 0;
    }
  }
}
