.timepicker {
  width: 80px;
  position: relative;
  display: flex;
  align-items: center;

  &:focus-within {
    color: #bdbdbd;
    background-color: #fff;
    border-color: #007bd2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 48, 82, 0.25);
  }

  .ngb-tp {
    .ngb-tp-input-container {
      width: 20px;
      input.ngb-tp-input.form-control {
        height: auto;
        padding: 0;
        margin: 0;
        background-color: transparent;

        &:focus {
          outline-style: none;
          box-shadow: none;
          border-color: transparent;
          color: inherit;
        }
      }
    }
  }

  &__icon {
    display: none;
  }
}
