.dropdown-menu {
  min-width: 100%;

  .dropdown-item {
    padding: 8px 5px;
    //&:hover {
    //    background-color: #f2f2f2;
    //}

    a:hover {
      text-decoration: none !important;
    }
  }
}

.default-dropdown {
  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    padding: 8px 16px;
    background-color: $gray-400;
    border-radius: 4px;
  }
}

.dropdown-item:hover {
  cursor: pointer;
}

.sub-menu {
  left: 100%;
  position: absolute;
  visibility: hidden;
  margin-top: -1px;
}

.sub-menu-0 {
  top: 0;
}

.sub-menu-1 {
  top: 43px;
}

.sub-menu-2 {
  top: 83px;
}

.sub-menu-3 {
  top: 123px;
}

.sub-menu-4 {
  top: 163px;
}

.sub-menu-5 {
  top: 203px;
}

.sub-menu-6 {
  top: 243px;
}

.sub-menu-7 {
  top: 283px;
}

.sub-menu-8 {
  top: 323px;
}

.sub-menu-9 {
  top: 363px;
}

.sub-menu-10 {
  top: 403px;
}

li.dropdown-item:hover > .sub-menu {
  visibility: visible;
  display: block;
}

.form-control[disabled] {
  color: $gray-700;
}

.ui-select-bootstrap {
  .ui-select-choices-row > span {
    white-space: normal;
  }

  .ui-select-match > span.btn {
    white-space: normal;
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
  }

  .ui-select-match-text span {
    display: inherit;
  }
}
