.icon-gray-800 svg path {
  fill: $gray-800;
  stroke: $gray-800;
}

.page-title {
  font-size: 27px;
  font-weight: 800;
  letter-spacing: 1.5px;
}

.text-upper {
  text-transform: uppercase;
}

.text-bold {
  font-weight: bold;
}

.cancelled-registration-row {
  text-decoration: line-through;
  button {
    text-decoration: line-through;
  }
}
.text-overflow-dynamic-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: flex;
  vertical-align: text-bottom !important;
}

.text-overflow-dynamic-ellipsis {
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}

.text-overflow-dynamic-container:after,
.text-overflow-dynamic-ellipsis:after {
  content: '-';
  display: inline;
  visibility: hidden;
}

.highlighted-match {
  font-weight: bold;
}

@mixin cancelled-event {
  color: $gray-600;
  text-decoration: line-through;

  &:hover {
    color: $gray-600;
    text-decoration: line-through;
  }
}
