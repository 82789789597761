.kn-tooltip {
  padding-bottom: 16px;

  .tooltip-inner {
    padding: 16px;
    background-color: $white;
    color: $body-color;
    box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.05);
    border-radius: 4px;
    border-color: rgba(35, 33, 61, 0.25);
  }
}
