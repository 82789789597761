.create-new-form {
  padding: 30px 40px;

  @include media-breakpoint-down('xs') {
    padding: 30px 20px;
  }

  &__name {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
  }

  &__description {
    width: 100%;
    font-size: 18px;
    font-weight: 500;
  }

  &__event-data {
    align-items: center;
  }

  &__section {
    margin-top: 40px;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $gray-400;

    h5 {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__add-item {
    display: flex;
    align-items: center;

    .default-dropdown {
      width: 100%;
    }
  }

  &__add-new {
    display: flex;
    align-items: center;
    color: $blue;
  }

  &__remove-icon {
    &:hover {
      cursor: pointer;
    }
  }

  &__buttons {
    .btn {
      min-width: 182px;
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
}

.button-row {
  padding-left: 10px;
  button {
    margin-left: 10px;
  }
}

.button-col {
  @include media-breakpoint-down('xs') {
    margin-top: 10px;
  }
}
