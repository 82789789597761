.kn-table {
  &__w100 {
    width: 100%;
  }

  font-size: 14px;

  th {
    font-weight: bold;
    color: $primary;
  }

  th,
  td {
    padding: 16px;
    vertical-align: middle !important; /*// overwrite bootstrap 'vertical-align: bottom'*/
    text-align: left;
  }

  &:not(.unstriped) {
    tbody {
      tr:nth-child(even) {
        background-color: $gray-400;
      }
    }
  }

  &--collapsable {
    &__category {
      span:not(:last-child) {
        margin-right: 16px;
      }
    }

    thead th {
      padding: 16px;
    }

    tbody {
      tr td {
        padding: 8px 16px;
      }

      tr:first-child td {
        padding: 16px;
      }
    }

    tbody:nth-child(even) tr {
      background-color: $gray-400;
    }

    tr:nth-child(even) {
      background-color: transparent;
    }
  }

  &.table-bordered {
    border: none;

    th,
    td {
      border-color: #f7f7f7;
    }
  }

  thead th {
    color: $gray-800;
    border-top: none;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  tbody {
    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
  }

  &__total {
    font-size: 18px;
    font-weight: bold;
  }

  &__body {
    &--empty {
      padding: 16px;
      font-size: 16px;
    }
  }
}

.enable-div-table {
  .div-table {
    font-size: 14px;
    .div-theader {
      font-weight: 700;
      color: $gray-800;

      .div-th {
        padding: 16px;
        vertical-align: middle;
        text-align: left;
      }
    }

    .div-tr {
      border-top: solid 1px $gray-200;

      .div-td {
        padding: 16px;
      }
    }

    &-striped {
      .div-tbody {
        .div-tr:nth-of-type(odd) {
          background-color: $gray-400;
        }
      }
    }

    &-highlighted {
      .div-tr {
        &:hover {
          background-color: $gray-1000 !important;
        }
      }
    }
  }
}

table.highlighted-rows {
  tbody {
    tr:hover {
      background-color: $gray-1000 !important;
    }
  }
}

.sortable {
  min-height: 40px;
  position: relative;
  cursor: pointer;

  &::after {
    margin-left: 5px;
    cursor: pointer;
  }

  .sortable__toggle {
    min-height: 26px;
  }

  &.unsorted {
    .sortable__toggle::after {
      content: url('/content/images/sortable.svg');
      vertical-align: -25%;
    }
  }

  &.sorted-desc {
    .sortable__toggle::after {
      content: url('/content/images/sorted-desc.svg');
      vertical-align: -10%;
    }
  }

  &.sorted-asc {
    .sortable__toggle::after {
      content: url('/content/images/sorted-asc.svg');
      vertical-align: -10%;
    }
  }
}

.sortable::after {
  position: relative;
  margin-left: 5px;
  cursor: pointer;
}

/*// styles for sortable items*/
.div-table-header__icon--unactive {
  display: none;
}
