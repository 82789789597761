.scroll-container {
  width: 100%;
  overflow-x: scroll;
}

.sh-block {
  box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.05);
  border-radius: 4px;
}

.section-heading {
  color: $primary;
  font-weight: bold;
  line-height: 120%;
  margin-bottom: 16px;
}

h2.section-heading {
  font-size: 24px;
}

h5.section-heading {
  font-size: 14px;
  text-transform: uppercase;
  color: $gray-900;
}

.container {
  @include media-breakpoint-down('sm') {
    padding-left: 5px;
    padding-right: 5px;
  }
}
