.ql-container.ql-snow {
  border: none;
}

.ql-editor {
  min-height: 148px;
  padding: 16px;
  background-color: $gray-400;
  border-radius: 4px;
}

.ql-snow.ql-preview img {
  max-width: 100%;
}

.ql-toolbar.ql-snow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: none;
  padding: 0;
  margin-bottom: 8px;

  span.ql-formats {
    margin-right: 4px;
    button {
      margin-right: 4px;
    }
  }

  .ql-header .ql-picker-label {
    border: none !important;
    padding: 8px !important;
    background-color: $gray-400 !important;
    height: auto !important;
    border-radius: 4px;

    &:before {
      line-height: initial;
      font-weight: normal;
    }
  }

  .ql-header.ql-picker {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
    float: none;
  }

  .ql-formats {
    display: flex;
    margin: 0;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  svg {
    width: 18px;
    vertical-align: unset;
  }

  .ql-bold,
  .ql-italic,
  .ql-underline,
  .ql-color.ql-color-picker,
  .ql-background.ql-color-picker,
  .ql-list,
  .ql-link {
    width: 40px;
    height: 33px;
    padding: 6px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    background-color: $gray-400;
    float: none;
  }
}
