/*//== Overwrite Bootstrap 4 Variables*/
:root {
  --red: #eb5757;
}
$black: #000 !default;
$gray-1000: #e0e0e0 !default;
$gray-900: #333333 !default;
$gray-800: #003052 !default;
$gray-700: #bdbdbd !default;
$gray-600: #8097a8 !default;
$gray-550: #676767 !default;
$gray-500: #828282 !default;
$gray-450: #c6c6c6 !default;
$gray-400: #f2f2f2 !default;
$gray-300: #fafafa !default;

$orange: #f58220 !default;
$red: #eb5757 !default;
$blue: #57b5dc !default;
$green: #219653 !default;
$sky-blue: #abdaed !default;
$white: #fff;

$primary: $gray-800 !default;
$secondary: $gray-600 !default;
$dark: $gray-800 !default;
$info: $blue !default;

$body-bg: $white !default;
$body-color: $gray-900;

$font-family-sans-serif: 'Rubik', Helvetica, Arial, sans-serif !default;

$wagMainColor: #ea5297;
$magMainColor: #009fe3;
$aggMainColor: #954b97;
$rgMainColor: #faba20;
$akroMainColor: #a9005d;
$kaMainColor: #e4032e;
$tgMainColor: #76b82a;
$tumMainColor: #ffd500;
$tanvoMainColor: #66c1bf;
$lumoMainColor: #d7bc4a;
$staraMainColor: #636363;
$tulDanceMainColor: #47d866;
$performanceMainColor: #7674ce;
$danceLumoMainColor: #f7a28b;

$highlightColor1: #f2f2f2;
$highlightColor2: #c4dfff;
$highlightColor3: #b9ebd6;
$highlightColor4: #feffb3;
$highlightColor5: #f4b6c6;
$highlightColor6: #e8d4ff;
$highlightColor7: #ffdfc6;
$highlightColor8: #bbefff;
$highlightColor9: #f7c2ff;
$highlightColor10: #ffe160;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
) !default;
