.enter-startlist-results {
  .col {
    &__num {
      width: 70px;
    }
    &__start-time {
      width: 110px;
    }
    &__category {
      width: 250px;
    }
    &__participant {
      width: 200px;
    }
    &__club {
      width: 220px;
    }
    &__program {
      width: 125px;
    }
    &__points {
      width: 130px;
    }
  }

  .expanded-row {
    border: 2px solid $blue;
    box-sizing: border-box;

    background-color: $white;
  }

  .btn-tab__toggle-btn {
    padding: 2px 6px;
    :hover {
      cursor: pointer;
    }
    svg path {
      fill: $gray-800;
    }
  }

  .controls {
    margin-left: 16px;
    button {
      margin-bottom: 10px;
    }
  }

  .points-group-header {
    padding-left: 25px;
    margin-top: 5px;
    margin-bottom: 5px;
    line-height: 48px;
    color: $gray-800;
    font-size: 14px;
    font-weight: 700;
    border-radius: 4px;
    background-color: $sky-blue;
  }

  .points-group-header-total {
    width: 100px;
    font-weight: 800;
  }

  .points-group--av2023 {
    padding-left: 30px;
  }

  .editable-value {
    padding-left: 0.75rem;
  }

  .results-form-container {
    padding: 0 15px 15px 15px;
  }

  .results-block {
    margin-top: 35px;
  }

  .deductions-display {
    min-width: 40px;
    width: 40px;
    margin-right: 10px;
  }

  .tanvo-partial-point,
  .lumo-partial-point {
    width: 82px;
    min-width: 82px;
    padding-left: 10px;
    .points-label {
      padding-right: 5px;
      color: $gray-500;
      min-width: 14px;
    }
  }

  .lumo-judge-row {
    padding-left: 30px;
    line-height: 48px;
  }

  .judge-point-row {
    height: 48px;
  }

  &__registration-total-points {
    font-weight: bolder;
    color: $blue;
  }

  &__jt-points-button {
    position: absolute;
    right: 0;
  }

  &__judge-point-row {
    line-height: 48px;
    padding-left: 25px;
  }

  &__points--published {
    color: #1f811f;
    font-weight: bolder;
  }
}
