.card-event {
  padding: 16px 16px 40px 16px;
  margin-bottom: 15px;

  .create-new-form {
    padding: 0;
  }

  .sh-block {
    box-shadow: none;
    border-radius: 0;
  }

  h1 {
    color: $primary;
    margin-bottom: 4px;

    a:hover {
      text-decoration: none;
    }
  }

  &--submitted h1 {
    color: $gray-600;
  }

  &--cancelled h1 a {
    @include cancelled-event;
  }

  &--approved h1 {
    color: $blue;
  }

  &--rejected h1 {
    color: $red;
  }

  &__description {
    color: $gray-800;
    font-size: 16px;
    margin-bottom: 12px;
  }

  &__info-item {
    &:not(:last-child) {
      margin-bottom: 8px;
    }

    .label {
      font-size: 12px;
      color: $gray-500;
      line-height: 120%;
      margin-bottom: 4px;
      text-transform: uppercase;
    }

    .label-empty {
      height: 16px;
    }

    .value {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__categories,
  &__members {
    border-left: 1px solid $gray-400;
  }

  &__members {
    .value:hover {
      color: $blue;
      cursor: pointer;
    }
  }
}
