.btn-sm-fs {
  font-size: 14px;
}

.default-btn {
  padding: 0;
  border: none;
  box-shadow: none;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
}

.btn-link {
  color: $blue;
  &:focus,
  &:hover {
    border: none;
    outline: none;
    text-decoration: none;
    color: $blue;
  }

  &:disabled {
    svg {
      path {
        fill: $gray-600;
      }
    }
  }
  svg {
    path {
      fill: $blue;
    }
  }
}

.btn {
  font-weight: bold;

  &--goback {
    color: $primary;
  }
}

.btn:disabled {
  background-color: $gray-400;
  color: $gray-700;
  border-color: $gray-400;
}

.btn-white {
  background-color: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: $red;

  border: 1px solid $red;

  &:hover {
    color: $red;
  }
}

%button {
  width: auto;
  height: auto;
  text-transform: none;
}

.btn-blue {
  @extend %button;
  background-color: $blue;
  border-color: $blue;
  color: $white;

  &:disabled {
    background-color: $blue;
    color: $white;
    opacity: 0.7;
  }

  &:hover {
    background-color: $blue;
    color: $white;
  }
}

.btn-orange {
  @extend %button;
  background-color: $orange;
  border-color: $orange;
  color: $white;

  &:disabled {
    background-color: $orange;
    color: $white;
    opacity: 0.7;
  }

  &:hover {
    background-color: $orange;
    color: $white;
  }
}

.btn-red {
  @extend %button;
  background-color: $red;
  border-color: $red;
  color: $white;

  &:disabled {
    background-color: $red;
    color: $white;
    opacity: 0.7;
  }

  &:hover {
    background-color: $red;
    color: $white;
  }
}

.btn-green {
  @extend %button;
  background-color: $green;
  border-color: $green;
  color: $white;

  &:disabled {
    background-color: $green;
    color: $white;
    opacity: 0.7;
  }

  &:hover {
    background-color: $green;
    color: $white;
  }
}

.btn-white-primary {
  background-color: $white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: $primary;

  border: 1px solid $white;
}

.btn-icon {
  border: 1px solid $secondary;
  &:focus {
    box-shadow: none;
  }
  &:disabled {
    background-color: $white;
  }
  &:hover {
    background-color: rgba($secondary, 0.2);
  }

  &--red {
    border-color: $red;
    &:hover {
      background-color: rgba($red, 0.2);
    }
  }
  &.playback-button {
    border: 0;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .25em .25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: .25rem;
  opacity: .5;
}

.btn-tool {
  margin-left: 10px;
}

.btn-overlay {
  border: none;
  padding: 0;
  &:hover {
    background-color: initial;
  }
  &:disabled {
    background-color: initial;
    &.btn-icon {
      svg {
        path {
          fill: $gray-500;
        }
      }
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-top-right {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 20;
  cursor: pointer;
}

a.underlined {
  text-decoration: underline;
}

.btn-overlay {
  border: none;
  padding: 0;
  &:hover {
    background-color: initial;
  }
  &:disabled {
    background-color: initial;
    &.btn-icon {
      svg {
        path {
          fill: $gray-500;
        }
      }
    }
  }
  &:focus {
    box-shadow: none;
  }
}

.btn-top-right {
  position: absolute;
  top: 5px;
  right: 10px;
  z-index: 20;
  cursor: pointer;
}

.btn-flat {
  border: none;
  &.btn-icon:hover {
    background-color: inherit;
  }
}

.btn-sm {
  width: 24px;
  height: 24px;
  padding: 4px;
}

.btn-row {
  > .btn:first-child {
    margin-left: 0;
  }
  > .btn:not(:first-child) {
    margin-left: 16px;
  }
}

.scroll-to-top {
  display: none;
  background-color: $gray-450;
  position: fixed;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: none;
  top: 20px;
  right: 0;
  z-index: 100000;
  cursor: pointer;
  margin: 10px;
  -moz-opacity: 0.6;
  opacity: 0.6;
  filter: alpha(opacity=60);
  padding: 0;

  &:hover {
    background-color: $gray-600;
  }
  &:focus {
    outline: none;
  }

  @media (max-width: 1380px) {
    right: 0;
  }
  @media (min-width: 1381px) {
    right: calc((100% - 1200px) / 2 - 90px);
  }
}
