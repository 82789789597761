@import '../vars';

.nav-tabs {
  margin-left: -15px;
  margin-right: -15px;
  border: none;

  .nav-link {
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    border: none;
    color: $gray-600;
    line-height: 120%;

    &:hover {
      border: none;
      color: $gray-800;
    }
  }

  .active {
    .nav-link {
      color: $gray-800;
    }
  }

  .nav-item {
    .nav-link.active {
      color: $gray-800;
    }
  }

  &.nav2 {
    .nav-link {
      font-weight: 600;
      padding-left: 0.75rem;
      padding-right: 0.75rem;
    }
  }

  &.nav2 {
    .nav-link {
      font-size: 24px;
    }
  }
  &.nav3 {
    .nav-link {
      text-transform: none;
      font-size: 18px;
    }
  }
}

.btn-tabs {
  &__tab button {
    color: $gray-500;
    font-size: 16px;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &:focus,
    &.active,
    &:hover {
      color: $body-color;
    }
  }
}

.btn-tab {
  font-size: 14px;
  margin-bottom: 16px;

  &__toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    border: none;
    background-color: $white;
    color: $orange;
    font-weight: bold;
    font-size: 18px;
    text-align: left;

    h2 {
      margin: 0;
    }

    &--blacked {
      color: $primary;

      svg path {
        fill: $primary;
      }
    }
  }

  &__toggle-btn {
    &_active {
      transform: rotate(180deg);
    }
  }

  &__content {
    margin-top: 8px;
  }
}

.btn-tab-nested {
  font-size: 14px;

  &__toggle {
    width: 100%;
    border: none;
    box-shadow: none;
    background-color: $white;
    user-select: text;
  }
  :focus {
    outline: none;
  }

  &__content {
    &--mobile {
      background-color: $gray-1000;

      .row {
        border-bottom: 1px solid $gray-400;
      }

      .col-6:not(:last-child) {
        border-right: 1px solid $gray-400;
      }
    }
  }
}
