.advertisement__image {
  width: 100%;
  height: auto;

  &--small {
    max-width: 100%;
    width: 100%;
  }

  &--large {
    max-width: 1200px;
  }
}
