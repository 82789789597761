#events {
  padding: 32px 0 0 0;

  .dropdown-menu {
    min-width: 310px;
  }
}

.events-wrapper {
  position: relative;

  .nav-tabs {
    margin-left: -30px;
    margin-right: -30px;
  }

  .nav-item {
    &:first-child {
      &.active .nav-link {
        color: $red;
      }
    }
  }
}

.events-search {
  position: absolute;
  top: 5px;
  right: 15px;

  @include media-breakpoint-down('md') {
    top: -15px;
    left: 15px;
  }

  .input-w-icon__input {
    @include media-breakpoint-down('md') {
    }
  }
}

.event-list {
  width: 100%;
  @include media-breakpoint-down('md') {
    overflow-x: hidden;
  }
}

.event-item {
  background-color: $white;
  box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.1);
  border-radius: 4px;
  overflow: visible;
  line-height: 120%;

  &__img {
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &-container {
      padding: 0;
      position: relative;
    }

    &-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(47.97deg, rgba(0, 48, 82, 0.7) 1.17%, rgba(0, 48, 82, 0.21) 100%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $white;
      font-size: 30px;
      font-weight: bold;
      line-height: 120%;
    }
  }

  &__virtual-tag {
    color: white;
    font-size: 16px;
    padding: 4px 6px;
    border-radius: 5px;
    margin-right: 5px;

    &_VIRTUAL {
      background-color: $gray-800;
    }
    &_ONLINE {
      background-color: $green;
    }
  }

  &__title {
    display: block;
    color: $primary;
    font-size: 30px;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 16px;
    &:hover {
      text-decoration: none;
    }
    &__cancelled {
      @include cancelled-event;
    }
  }

  &__title {
    display: block;
    color: $primary;
    font-size: 30px;
    font-weight: bold;
    line-height: 120%;
    margin-bottom: 4px;
    &:hover {
      text-decoration: none;
    }
    &__cancelled {
      @include cancelled-event;
    }
  }

  &__subtitle {
    color: $gray-800;
    font-weight: 600;
  }

  &__info-container {
    padding: 16px 16px 0 16px;
  }

  &__info {
    margin-bottom: 8px;
  }

  &__info-item {
    @include media-breakpoint-down(xs) {
      .label {
        margin-top: 8px;
      }
    }
    .label {
      font-size: 12px;
      color: $gray-500;
      margin-bottom: 4px;
      line-height: 100%;
      text-transform: uppercase;
    }
    .value {
      color: $gray-900;
      font-weight: bold;
    }
  }

  &__event-date {
    border: #dcdcdc 1px solid;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin-top: -10px;
    max-height: 40px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 4px;
    margin-bottom: 0px;

    &-item {
      display: flex;
      align-items: center;
      margin-right: 8px;
      margin-bottom: 8px;
      padding-right: 5px;
      font-size: 14px;
      border: 1px solid #f58220;
      border-radius: 16px;
      overflow: hidden;

      .type {
        padding: 0 5px;
        margin-right: 5px;
        color: $white;
      }

      &-RHYTHMIC_GYMNASTICS {
        color: $rgMainColor;
        border-color: $rgMainColor;
        .type {
          background-color: $rgMainColor;
        }
      }
      &-WAG {
        color: $wagMainColor;
        border-color: $wagMainColor;
        .type {
          background-color: $wagMainColor;
        }
      }
      &-MAG {
        color: $magMainColor;
        border-color: $magMainColor;
        .type {
          background-color: $magMainColor;
        }
      }
      &-AKRO {
        color: $akroMainColor;
        border-color: $akroMainColor;
        .type {
          background-color: $akroMainColor;
        }
      }
      &-KA {
        color: $kaMainColor;
        border-color: $kaMainColor;
        .type {
          background-color: $kaMainColor;
        }
      }
      &-TUM {
        color: $tumMainColor;
        border-color: $tumMainColor;
        .type {
          background-color: $tumMainColor;
        }
      }
      &-TG {
        color: $tgMainColor;
        border-color: $tgMainColor;
        .type {
          background-color: $tgMainColor;
        }
      }
      &-TANVO {
        color: $tanvoMainColor;
        border-color: $tanvoMainColor;
        .type {
          background-color: $tanvoMainColor;
        }
      }
      &-AGG {
        color: $aggMainColor;
        border-color: $aggMainColor;
        .type {
          background-color: $aggMainColor;
        }
      }
      &-STARA {
        color: $staraMainColor;
        border-color: $staraMainColor;
        .type {
          background-color: $staraMainColor;
        }
      }
      &-LUMO,
      &-TUL_DANCE,
      &-GFL {
        color: $lumoMainColor;
        border-color: $lumoMainColor;
        .type {
          background-color: $lumoMainColor;
        }
      }
      &-DANCE_LUMO {
        color: $danceLumoMainColor;
        border-color: $danceLumoMainColor;
        .type {
          background-color: $danceLumoMainColor;
        }
      }
    }
  }

  &__more-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 45px;
    padding: 3px 5px;
    background-color: $gray-600;
    border-radius: 16px;
    color: $white;
  }

  &__btns {
    padding: 0 15px 15px 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: -10px;
  }

  @include media-breakpoint-down(xs) {
    &__btns {
      padding-top: 15px;
    }
  }

  &__col {
    padding-right: 0;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.search-input {
  &_opened {
    display: block;
  }

  &_hidden {
    display: none !important;
  }

  &_disable {
    opacity: 0;
    pointer-events: none;
  }

  &__input {
    width: 100%;
    color: $gray-700;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
    overflow: hidden;

    &:focus-visible {
      outline: none;
    }
  }

  &__show-icon {
    @include media-breakpoint-down('xs') {
      background: linear-gradient(270deg, $body-bg 61.89%, rgba(255, 255, 255, 0) 117.65%);
      width: 75px;
      top: 0px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}

.events-tabs {
  .nav-tabs {
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    @include media-breakpoint-down('xs') {
      margin-left: 0;
      margin-right: 0;
      flex-wrap: wrap;
    }
  }

  @include media-breakpoint-down('md') {
    padding-top: 60px;
  }
}

.events-filter {
  .dropdown-toggle {
    &:after {
      content: none;
    }
    &:focus {
      box-shadow: none;
    }
  }

  &.active .dropdown-toggle path {
    fill: $primary;
  }

  &.open .dropdown-toggle path {
    fill: $blue;
  }

  .dropdown-menu {
    left: auto;
    right: 0;
    top: 45px;
    border: none;
    padding: 16px;
  }

  &__section:not(:last-child) {
    margin-bottom: 16px;
  }

  &__heading {
    color: $primary;
    margin-bottom: 16px;
  }
}

.icon-counter {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $blue;

  &__value {
    font-size: 10px;
    color: $white;
    line-height: 0;
  }
}

.events-filter-divider {
  margin: 0 16px;
}

.events-filter-date {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-control {
    min-width: 88px;
  }
}

.events-filter-sports {
  .custom-checkbox {
    margin-bottom: 12px;
  }
}
