.fade.in {
  opacity: 1;
}

.modal.in .modal-dialog {
  transform: translate(0, 0);
}

.modal-backdrop {
  z-index: 800 !important;
}
.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-anchor {
  position: absolute;
}

.modal-content {
  padding: 10px 40px 40px 40px;
}

.modal-body {
  font-size: 16px;
  padding: 0;
}

.modal-header {
  border-bottom: 0;
  margin-right: -30px;
  margin-top: -10px;
  padding-right: 0;
  padding-left: 0;

  .close {
    margin: 0;
    padding: 10px 20px;
    &:focus {
      outline: none;
    }
  }
}

.modal-title {
  width: 100%;
  padding-top: 6px;
  font-size: 24px;
  color: $primary;
  font-weight: bold;
  &.danger {
    color: $red;
  }
}

.modal-descrption {
  text-align: center;
  margin-bottom: 40px;
}

.modal-buttons {
  margin-top: 40px;
}

.modal-dialog {
  max-width: 430px;
}

.confirm-modal {
  .modal-buttons {
    display: flex;
    justify-content: space-around;

    & > div {
      display: flex;
      gap: 20px;
      justify-content: space-around;
    }

    .btn {
      @include media-breakpoint-down('sm') {
        width: 140px;
      }
      width: 170px;
    }
  }
}
