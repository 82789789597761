.start-list-create {
  .btn-tab__toggle-btn {
    padding: 16px;
    cursor: pointer;
  }

  .dndPlaceholder {
    min-height: 100px;
  }
}

.dndPlaceholder {
  background-color: #ddd;
  display: block;
  min-height: 42px;
}

li.dndPlaceholder {
  list-style-type: none;
}

.dndDraggingSource {
  display: none;
}

.dndDragging {
  opacity: 0.7;
}



.default-sl_editor {
  &__replace-placeholder-btn-container {
    border-left: 1px solid #00000033;
  }
}

.kn-table.start-list-panel-table,
.comp-create-block {
  width: 100%;
  .start-list-table-row {
    &-1 {
      background-color: $white;
    }
    &-2 {
      background-color: #C4DFFF;
    }
    &-3 {
      background-color: #B9EBD6;
    }
    &-4 {
      background-color: #FEFFB3;
    }
    &-5 {
      background-color: #F4B6C6;
    }
    &-6 {
      background-color: #E8D4FF;
    }
    &-7 {
      background-color: #FFDFC6;
    }
    &-8 {
      background-color: #BBEFFF;
    }
    &-9 {
      background-color: #F7C2FF;
    }
    &-10 {
      background-color: #FFE160;
    }
  }
}

.start-list-table-row_selected {
  background-color: #ffe160 !important;
}

.kn-table.start-list-table {
  td,
  th {
    text-align: left !important;
    vertical-align: top !important;

    &.actions {
      width: 90px;
    }
    &.orderNumber {
      width: 60px;
    }
    &.start-time {
      width: 75px;
    }
    &.participant {
      width: 205px;
    }
    &.category {
      width: 205px;
    }
    &.club {
      width: 200px;
    }
    &.program {
      width: 120px;
    }
    &.panel {
      width: 120px;
    }
  }

  &__print {
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  .ui-sortable-placeholder {
    height: 50px;
    border: 2px solid gray;
  }
  .ui-sortable-helper {
    border: 1px solid gray;
  }

  .column-container {
    display: table-row;
    table-layout: fixed;
  }

  div.start-list-column {
    padding-left: 8px;
    padding-right: 8px;
    display: table-cell;
  }

  &.start-list-multi-column-table {
    th,
    td {
      padding-left: 8px;
      padding-right: 8px;
    }
    th {
      &.orderNumber {
        width: 10%;
      }
      &.start-time {
        width: 20%;
      }
      &.participant {
        width: 40%;
      }
      &.program {
        width: 30%;
      }
    }
    .ui-sortable-placeholder {
      height: 100px;
      border: 2px solid gray;
    }
    .ui-sortable-helper {
      border: 1px solid gray;
    }
  }

  table.uib-timepicker {
    td {
      padding: 0;
      &.uib-time {
        input {
          padding: 0;
          width: 30px;
          height: 35px;
        }
      }
      &.uib-separator {
        padding: 8px 0 0 0;
      }
    }
  }
}

.last-panel-cell {
  border-right: 1px $gray-500 solid;
}

.start-list-entry-status_invalid {
  svg {
    fill: $red;
  }
}

.start-list-entry-status-popover {
  padding: 16px;
}

.ag-start-list-editor,
.start-list-editor {
  .cancelled-registration-row {
    background-color: #ffd5cb !important;
  }

  &__table {
    table-layout: fixed;
    tr {
      th {
        &:nth-child(1) {
          width: 5%;
        }
        &:nth-child(2) {
          width: 30%;
        }
        &:nth-child(3) {
          width: 60%;
        }
        &:nth-child(4) {
          width: 5%;
        }
      }
    }
  }

  .tab-nav-2 {
    .nav-tabs {
      .nav-link {
        text-transform: initial;
      }
    }
  }
}

.icon-toggle.dropdown-toggle {
  &::after {
    display: none !important;
  }
}

.icon-dropdown-menu.dropdown-menu {
  min-width: 10rem;
}


#practice-start-list-cb {
  margin-bottom: 20px;
}

.entry-panel-editor,
.entry-duration-editor {
  width: 225px;
  padding: 16px;
  .custom-control.custom-radio {
    padding-left: 0;
  }

  .ok-btn {
    width: 100px;
  }

  &__title {
    padding: 10px 20px;
  }
}

.entry-duration-editor {
  width: 235px;
}

.sl-column-selection-popover {
  width: 320px;
  max-width: 320px;
  padding: 20px;
}
