.kn-audio-track {
  width: 100%;
  height: 100%;
  position: relative;

  .progress {
    border-radius: 0;
    height: 100%;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .progress-bar {
      background-color: $blue;
    }

    &.show-wave.loaded {
      .progress-bar {
        transition: background-color 0.5s ease-in-out;
        transition-delay: 0.35s;
        background-color: transparent;
      }
    }
  }

  &__playing {
    width: 100%;
    height: 100%;

    &:not(.show-wave) {
      wave > wave {
        background-color: $primary;
        border-right: $primary !important;
        canvas {
          display: none;
        }
      }
    }
  }
}
