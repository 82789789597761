@import './vars';

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/root';
@import 'node_modules/bootstrap/scss/reboot';
@import 'node_modules/bootstrap/scss/type';
@import 'node_modules/bootstrap/scss/images';
@import 'node_modules/bootstrap/scss/code';
@import 'node_modules/bootstrap/scss/grid';
@import 'node_modules/bootstrap/scss/tables';
@import 'node_modules/bootstrap/scss/forms';
@import 'node_modules/bootstrap/scss/buttons';
@import 'node_modules/bootstrap/scss/transitions';
@import 'node_modules/bootstrap/scss/dropdown';
@import 'node_modules/bootstrap/scss/button-group';
@import 'node_modules/bootstrap/scss/input-group';
@import 'node_modules/bootstrap/scss/custom-forms';
@import 'node_modules/bootstrap/scss/nav';
@import 'node_modules/bootstrap/scss/navbar';
@import 'node_modules/bootstrap/scss/card';
@import 'node_modules/bootstrap/scss/breadcrumb';
@import 'node_modules/bootstrap/scss/pagination';
@import 'node_modules/bootstrap/scss/badge';
@import 'node_modules/bootstrap/scss/jumbotron';
@import 'node_modules/bootstrap/scss/alert';
@import 'node_modules/bootstrap/scss/progress';
@import 'node_modules/bootstrap/scss/media';
@import 'node_modules/bootstrap/scss/list-group';
@import 'node_modules/bootstrap/scss/close';
@import 'node_modules/bootstrap/scss/toasts';
@import 'node_modules/bootstrap/scss/modal';
@import 'node_modules/bootstrap/scss/tooltip';
@import 'node_modules/bootstrap/scss/popover';
@import 'node_modules/bootstrap/scss/carousel';
@import 'node_modules/bootstrap/scss/spinners';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/print';

@import '~node_modules/ui-select/dist/select.min.css';

@import '~node_modules/@ng-select/ng-select/themes/default.theme.css';

@import '~node_modules/quill/dist/quill.bubble.css';

/* Utilities */
@import './utils/text';
@import './utils/container';
@import './utils/spaces';
@import './utils/grid';
@import './utils/md';

/* Components */
@import './components/base';
@import './components/layout';
@import './components/nav';
@import './components/promo';
@import './components/tabs';
@import './components/btns';
@import './components/inputs';
@import './components/modals';
@import './components/card';
@import './components/collapse';
@import './components/ql-editor';
@import './components/dropdown';
@import './components/calendar';
@import './components/block-field';
@import './components/card-event';
@import './components/checkbox';
@import './components/radio';
@import './components/table';
@import './components/forms';
@import './components/file-upload';
@import './components/alerts';
@import './components/carousel';
@import './components/cookie';
@import './components/timepicker';
@import './components/team-card';
@import './components/audio';
@import './components/toggle';
@import './components/copy-to-cb';
@import './components/tooltip';
@import './components/start-list-group';
@import './components/start-list';
@import './components/advertisement';
@import './components/toast';
@import './components/ribbon';
@import './components/ngx-select';
@import './components/editable-startlist-table';
@import './components/tg-start-list-table';
@import './components/ad-banner-editor';
@import './components/banner-settings';
@import './components/front-page-banner';
@import './components/quill';
@import './components/results';
@import './components/ng-select';
@import './components/copy-to-final';
@import './components/spinner';
@import './components/tg-start-list';
/*// Pages*/
@import './pages/eventsearch';
@import './pages/event-details';
@import './pages/event-dialog';
@import './pages/planned-event';
@import './pages/users';
@import './pages/category-results';
@import './pages/myclub';
@import './pages/teams';
@import './pages/judges';
@import './pages/my-events';
@import './pages/event-competition';
@import './pages/protocols';
@import './pages/registrations';
@import './pages/login';
@import './pages/auth';
@import './pages/license';
@import './pages/startlist';
@import './pages/event-application';
@import './pages/judge-panels';
@import './pages/start-list-enter-results';
@import './pages/reports';
@import './pages/category-registrations';
@import './pages/tg-start-list-editor';
@import './pages/rg-start-list-editor';
@import './pages/judge-terminal';
@import './pages/settings';
