.custom-control.custom-radio {
  position: relative;

  label {
    padding-left: 30px;
    margin-bottom: 0;
    color: $body-color;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 9px);
      left: 0;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid $primary;
    }

    &:after {
      top: calc(50% - 8px);
      left: 1px;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-10 -10 20 20'%3e%3ccircle r='10' fill='%23fff'/%3e%3c/svg%3e");
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label:after {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    top: calc(50% - 5px);
    left: 4px;
    background-color: $white;

    border-radius: 50%;
  }

  input:checked + label:before {
    border-color: $blue;
    background-color: $blue;
  }
}
