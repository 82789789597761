.judge-panels {
  &__tab-content {
    padding: 24px 16px;
  }

  .btn-submit {
    width: 182px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50px;

    .delete-icon svg path {
      fill: $red;
      z-index: 1;
    }
  }
}

.judge-panel-form {
  padding: 16px 24px;

  &__name {
    margin-bottom: 40px;
  }

  &__label {
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__section {
    &:not(:last-child) {
      margin-bottom: 40px;
    }
  }

  &__footer {
    padding-top: 16px;
    margin-top: 40px;
    border-top: 1px solid $gray-400;
    .btn {
      width: 182px;
    }
  }
}

.judge-group {
  margin-bottom: 40px;

  &__heading {
    margin-bottom: 16px;
  }

  &__list {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    width: 60px;
    padding-right: 8px;
  }
}

.judges-list {
  margin-top: 16px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: -24px;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &--selected {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }

  &__delete-icon:hover {
    cursor: pointer;
  }

  &__controls {
    display: flex;
    align-items: center;

    .ui-select-container {
      width: 182px;
      margin-right: 8px;
    }
  }
}
