ngb-timepicker.tg-sl-table__time-picker {
  .ngb-tp-spacer {
    width: 0.3em;
  }
  .ngb-tp-input-container {
    width: 2em;
    input.ngb-tp-input.form-control {
      padding: 0;
      width: 2em;
    }
  }
}
