quill-editor {
  width: 100%;
}

.ql-editor ol > li[data-list="bullet"] {
  &::before {
    content: "";
  }
}

div.ql-preview .ql-editor {
  background-color: transparent;
  padding: 0;
  min-height: auto;
}

div.ql-preview .ql-editor h1,
div.ql-preview h1,
.kn-quill div.ql-editor h1 {
  margin-top: 0.6em;
  margin-bottom: 0.6em;
  font-size: 2.4em;
  font-weight: 400;
}

div.ql-preview .ql-editor h2,
div.ql-preview h2,
.kn-quill div.ql-editor h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-size: 2em;
  font-weight: 400;
}

div.ql-preview .ql-editor h3,
div.ql-preview h3,
.kn-quill div.ql-editor h3 {
  margin-bottom: 0.33em;
  margin-top: 0.33em;
  font-size: 1.5em;
  font-weight: 400;
}

div.ql-preview .ql-editor p,
.kn-quill div.ql-editor p {
  font-size: 16px;
  line-height: 1.42;
  margin: 0;
}

div.ql-preview {
  min-height: 100%;
  max-height: 100%;
}

div.ql-snow {
  font-size: 100%;
}

.ql-preview {
  p {
    margin: 0;
    &:empty::before {
      content: "";
      display: inline-block;
    }
  }
  h1, h2, h3, h4, h5, h6 {
    &:empty::before {
      content: "";
      display: inline-block;
    }
  }
}
