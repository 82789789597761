.block-field {
  &__label {
    font-size: 12px;
    color: $gray-500;
    line-height: 120%;
    margin-top: 8px;
    margin-bottom: 2px;
    text-transform: uppercase;
  }

  &__label-empty {
    height: 16px;
  }

  &__value {
    font-size: 16px;
    font-weight: bold;
  }
}
