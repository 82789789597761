.teams {
  &__create-btn {
    min-width: 182px;
  }

  &__card {
    display: block;
    padding: 16px;
    font-weight: bold;
    color: $body-color;
    font-size: 18px;
    margin-bottom: 8px;
  }
}

.team-members-item {
  padding: 4px;
  margin-top: 2px;
  margin-bottom: 2px;

  &:hover {
    background-color: $gray-400;
    cursor: pointer;
  }

  &.member-error {
    background-color: #ffbcb7;
    &:hover {
      background-color: #ff9283;
      cursor: pointer;
    }
  }
}

.team-detail {
  &__footer {
    /* // Reset legacy code*/
    position: unset;
    bottom: unset;
    height: unset;
    background: unset;

    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $gray-400;

    display: flex;
    justify-content: flex-end;

    .btn {
      width: 280px;
    }
  }
}
