.rg-start-list-editor {
}

.rg-start-list-group-editor {
  &__group-count {
    width: 60px;
  }

  &__button {
    min-width: 100px;
  }
}
