.login-form {
  .custom-checkbox label {
    padding-left: 25px;
  }

  &__support-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
