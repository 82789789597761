.header {
  border-radius: 0;
  background-color: #fff;

  .navbar-collapse {
    @include media-breakpoint-down('md') {
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1000;
      min-width: 10rem;
      padding: 0 0.5rem;
      background-color: #fff;
      box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.1);
    }
  }

  .nav {
    @include media-breakpoint-down('md') {
      display: block;
    }
  }

  .user {
    @include media-breakpoint-down('xs') {
      padding-right: 4px;
    }
    .user-name {
      margin-left: 10px;
      white-space: nowrap;
    }
  }

  &__nav-item {
    .nav-link {
      &.active {
        color: $blue;
      }
    }
    width: max-content;
    margin-left: auto;
    margin-right: 0;
  }

  .menu-toggler-container {
    @include media-breakpoint-down('xs') {
      padding-left: 2px;
    }
    .navbar-toggler {
      color: $blue;
      &:focus {
        outline: none !important;
      }
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgb(0,48,82)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  }

  &__nav-login {
    width: 30px;
    height: 30px;
    margin-top: -5px;
    margin-bottom: -5px;
    border-radius: 50%;
    background-color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:after {
      content: none;
    }
  }

  &_fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .dropdown-toggle {
    cursor: pointer;
    &.lang-toggle::after {
      display: none;
    }
  }

  &_transparent {
    background-color: transparent;

    .nav-link,
    .lang-toggle {
      color: $white;
    }

    .header__nav-login {
      background-color: $white;

      path {
        fill: $primary;
      }
    }

    .header-logo-letter {
      fill: $white;
    }

    .login-btn {
      background-color: $white;
      color: $primary;
      border-color: $white;
    }
  }
}

.logo-wrapper {
  position: relative;
  padding-right: 30px;

  @include media-breakpoint-down('xs') {
    padding-right: 10px;
  }

  a.navbar-brand {
    @include media-breakpoint-down('xs') {
      margin-right: 8px !important;
    }
  }

  &__lang {
    margin-top: -4px;
    text-transform: uppercase;
    text-align: center;
    height: 40px;
    padding-top: 8px;
    border: $gray-800 1px solid;
    border-radius: 5px;
    padding-left: 15px;
    padding-right: 15px;

    @include media-breakpoint-down('xs') {
      margin-top: 4px;
    }
  }
}

/*// Overwriting bootstrap rule*/
.dropdown.open .dropdown-menu {
  display: block;
}

.login-btn {
  font-weight: bold;
  white-space: nowrap;
}
