.auth-page {
  &__heading {
    text-align: center;
  }

  &__main {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .invalid-feedback {
    display: block;
  }

  &__buttons {
    margin-top: 40px;

    .btn-block {
      width: 100%;
    }
  }
}
