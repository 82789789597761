.team-card {
  padding: 40px;

  &__header {
    margin-bottom: 16px;

    h2 {
      margin: 0;
    }
  }

  &__block:not(:last-child) {
    margin-bottom: 40px;
  }

  &__main {
    padding-bottom: 40px;
  }

  &__footer {
    padding-top: 16px;
    border-top: 1px solid $gray-400;

    /*// Reset legacy code*/
    position: unset;
    bottom: unset;
    height: unset;
    background: unset;

    .btn:not(.btn-icon) {
      min-width: 182px;
    }
  }
}
