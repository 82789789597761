#toast-container {
  position: fixed;
  top: 0px;
  right: 0px;
  width: 100%;
  z-index: 999999;
  overflow: visible !important;
  @include media-breakpoint-down('md') {
    width: 100%;
  }
}

.global-toasts {
  position: fixed;
  top: 0px;
  right: 0;
  margin: 15px 15px 0 0;

  @include media-breakpoint-down('md') {
    width: calc(100% - 30px);
  }
}

.toast {
  width: 350px;

  .toast-header {
    padding: 12px;
    min-height: 60px;
    font-weight: 600;
    font-size: 15px;
  }

  .toast-body {
    height: 0;
    padding: 0;
  }

  &.info {
    div.toast-header {
      background-color: #b7ffb7ff !important;
      color: #333333;
    }
  }
  &.error {
    div.toast-header {
      background-color: $red;
      color: white;
    }
  }
  &.warning {
    div.toast-header {
      color: #730707;
      background-color: $yellow;
    }
  }

  @include media-breakpoint-down('md') {
    width: 100%;
  }

  &.app-update {
    button {
      min-width: 100px;
    }
  }
}
