.toggle,
.toggle-handle {
  border: $gray-800 1px solid;
  .toggle-handle {
    background-color: $white;
    margin-right: 0 !important;
  }

  .toggle-off-pad.btn {
    background-color: $gray-200;
    color: $gray-700;
    margin-right: 0 !important;
  }

  &:focus {
    box-shadow: none;
  }
}
