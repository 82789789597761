.input-w-icon {
  position: relative;

  &__input {
    padding-right: 30px;

    &_right {
      padding-right: 0;
      padding-left: 30px;
    }

    &_unit {
      padding-right: 40px;
    }
  }

  &__icon {
    position: absolute;
    top: calc(50% - 15px);
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;

    &_left {
      right: auto;
      left: 0;
    }

    &_unit {
      right: 8px;
      color: $gray-500;
    }
  }
}

.input-outline {
  background-color: transparent;
  box-shadow: none;
  border: none;
  border-radius: 0;
  -webkit-appearance: none !important;
  border-bottom: 2px solid $gray-700;
  outline: none;

  &--lg {
    font-size: 24px;
    width: 100%;
  }

  &--secondary {
    border-bottom: 1px solid $gray-700;
  }
}

.form-group {
  label {
    font-size: 12px;
    color: $gray-700;
    margin-bottom: 8px;
  }
}

.form-control {
  background-color: $gray-400;
  border: none;
  color: $black;

  &:focus {
    color: $gray-500;
  }

  &::-webkit-input-placeholder {
    /* Edge */
    color: $gray-500;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $gray-500;
  }

  &::placeholder {
    color: $gray-500;
  }

  &[readonly] {
    background-color: $gray-400;

    &:focus {
      box-shadow: none;
      outline: none;
      color: inherit;
    }
  }
}

%invalidControl {
  border: 2px solid $red !important;
  box-shadow: none;
}

%invalidOutlineControl {
  border: none !important;
  border-bottom: 2px solid $red !important;
}

form.ng-pending {
  input,
  .form-control {
    &.ng-invalid {
      @extend %invalidControl;
    }
  }

  .input-outline {
    &.ng-invalid {
      @extend %invalidOutlineControl;
    }
  }

  .ui-select-bootstrap.ng-invalid {
    .ui-select-toggle {
      @extend %invalidControl;
    }
  }
}

input,
.form-control {
  &.ng-invalid.ng-touched {
    @extend %invalidControl;
  }
}

.input-outline {
  &.ng-invalid.ng-touched {
    @extend %invalidOutlineControl;
  }
}

.ui-select-bootstrap.ng-invalid.ng-touched {
  .ui-select-toggle {
    @extend %invalidControl;
  }
}

.ui-select-bootstrap.ng-invalid-unique_code {
  .ui-select-toggle {
    @extend %invalidControl;
  }
}

.btn-default-focus {
  color: inherit;
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  outline: none;
  outline-offset: 0;
  box-shadow: none;

  .form-control {
    color: $gray-500;
    background-color: #fff;
    border-color: #007bd2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 48, 82, 0.25);
  }
}

.ui-select-container {
  width: 100%;

  &:after {
    position: absolute;
    top: 16px;
    right: 16px;
    content: '';
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  .form-control {
    box-sizing: border-box;

    &:focus {
    }
  }

  .text-muted {
    color: $gray-700 !important;
    font-weight: normal;
  }

  .ui-select-toggle {
    padding: 8px 16px;
    a.btn {
      top: 15px;
      /*fixme dirty hack*/
      background: url(https://cdnjs.cloudflare.com/ajax/libs/select2/3.4.5/select2.png) 20px 28px;
    }
  }

  .ui-select-match-text span {
    font-weight: normal;
  }
}

.ui-select-bootstrap > .ui-select-choices,
.ui-select-bootstrap > .ui-select-no-choice {
  max-height: 350px;
}

.ui-select-multiple.ui-select-bootstrap {
  padding-right: 40px;
}

input[type='number'].no-spin::-webkit-outer-spin-button,
input[type='number'].no-spin::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'].no-spin {
  -moz-appearance: textfield;
}

input[type='number'].custom-spin {
  padding-right: 16px;
}

a.custom-spin {
  display: inline-block;
  width: 12px;
  height: 8px;

  &.plus {
    background: url('/content/images/arrow_up.svg') 0 0 no-repeat;
    position: absolute;
    top: 8px;
    right: 20px;
  }

  &.minus {
    background: url('/content/images/arrow_down.svg') 0 0 no-repeat;
    position: absolute;
    top: 24px;
    right: 20px;
  }
}
