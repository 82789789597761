.category-results {
  &__judge-section {
    margin-top: 2em;
  }
  &__judge-section-header {
    margin-bottom: 2em;
  }
  &__judge-table {
    table-layout: fixed;
    font-size: 14px;
    width: unset;
  }
  &__judge-table-header {
  }
  &__judge-table-code-col {
    width: 100px;
  }
  &__judge-table-name-col {
    width: 300px;
  }

  &__judge-name-tooltip {
    .tooltip-inner {
      background-color: $white;
      color: $gray-800;
    }
  }

  &__advertisement {
    margin-top: -20px;
    box-shadow: 0 4px 20px rgba(35, 33, 61, 0.1);
  }
}

.results-cards {
  &__item {
    &--flex {
      display: flex;
    }

    &:not(:first-child) {
      @include media-breakpoint-down('sm') {
        margin-top: 8px;
      }
    }
  }
}

.results-header {
  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }

  &__actions,
  &__dropdown {
    @include media-breakpoint-up('sm') {
      min-width: 353px;
    }
  }

  &__actions {
    @include media-breakpoint-down('sm') {
      margin-top: 16px;
    }
  }

  /*// make the ui-select long items wrap nicely*/

  @include media-breakpoint-down('sm') {
    .ui-select-choices {
      .ui-select-choices-row {
        line-height: 45px;
        &.active {
          background-color: #428bca;
        }
      }
      .ui-select-choices-row-inner {
        white-space: normal;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.results-card {
  padding: 16px;

  &--stick-bottom {
    width: 100%;
    align-self: flex-end;

    .results-card__title {
      margin-bottom: 0;
    }
  }

  &__status {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 120%;
    text-transform: uppercase;

    &--current {
      color: $blue;
    }

    &--next {
      color: $gray-500;
    }
  }

  &__title {
    margin-bottom: 4px;
  }

  &__numbers {
    color: $blue;
  }

  &__description {
    margin-bottom: 0;
    color: $gray-500;

    &--top {
      margin-bottom: 8px;
    }
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }
}

.prog-result__ranking, .rg-prog-result__apparatus-ranking {
  font-size: 13px;
  color: #a0a0a0;
}

.results-table {
  font-size: 15px;

  th.agg-results-basic-table {
    &__result-col:last-child {
      width: 120px;
    }
  }

  th,
  td {
    padding: 0;
    text-align: center;
    padding: 10px;
    &.text-left {
      padding-left: 16px;
    }

    .ignored {
      color: #a0a0a0;
    }

    .deductions {
      color: $red;
      &.ignored {
        color: #b07e7e;
      }
    }
    .bonus {
      color: $green;
    }

    &.rg-prog-result-col,
    &.rg-prog-result-col--narrow {
      width: 180px;
    }

    &.rg-prog-result-col--wide {
      width: 210px;
    }

    &.rg-place-column--sortable {
      padding-left: 0;
      padding-right: 0;
      width: 65px;
    }

    &:first-child {
      width: 60px;
    }
    &:last-child {
      width: 90px;
    }

    &.row1-item {
      padding: 12px 5px;
    }

    .participant {
      font-weight: 600;
    }
    .team-competition-member {
      font-weight: 400;
    }
    .team-members {
      font-size: 13px;
    }
  }

  th {
    padding-bottom: 0 !important;
  }

  &.compact {
    th,
    td {
      &.rg-prog-result-col {
        width: 160px;
      }
    }

    .sub-points-2.last-col {
      padding-left: 10px;
    }
  }

  .points-placeholder {
    color: $gray-500;
  }

  &__agg {
    td {
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .position {
      min-width: 40px;
      width: 50px;
    }
    .points-column {
      width: 85px;
    }
    .participant-column {
      width: 320px;
    }

    .round-column {
      width: 300px;
    }

    .round-column-1 {
      width: 400px;
    }

    .round-total {
      font-weight: 600;
    }

    .total-points {
      font-size: 18px;
      font-weight: bold;
    }

    .deductions,
    .bonus {
      font-size: 12px;
      margin-left: -5px;
    }

    @include media-breakpoint-down('xs') {
      thead {
        tr {
          th:nth-child(1) {
            width: 10%;
          }
          th:nth-child(2) {
            width: 65%;
          }
          th:nth-child(3) {
            width: 25%;
          }
        }
      }
    }
  }

  &__rg {
    @include media-breakpoint-up('lg') {
      min-width: 930px;
      width: 1250px;
      margin-left: -40px;
    }
  }

  &__akro {
    @include media-breakpoint-up('lg') {
      min-width: 930px;
    }
  }

  tbody {
    tr:nth-child(even) {
      background-color: $gray-400;
    }
  }

  &--collapsable {
    &__category {
      span:not(:last-child) {
        margin-right: 16px;
      }
    }

    .club-name {
      color: $gray-500;
    }

    thead th {
      padding: 16px;
    }

    tbody {
      tr td {
        padding: 8px 16px;
      }

      tr:first-child td {
        padding: 8px;
      }
    }

    tbody:nth-child(even) tr {
      background-color: $gray-400;
    }

    tbody:nth-child(odd) tr {
      background-color: $white;
    }

    tr:nth-child(even) {
      background-color: transparent;
    }
  }

  &.table-bordered {
    border: none;

    th,
    td {
      border-color: #c4c4c4;
    }
  }

  thead th {
    color: $gray-800;
    border-top: none;

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  tbody {
    td:first-child {
      border-left: none;
    }

    td:last-child {
      border-right: none;
    }
  }

  &__total {
    font-size: 18px;
    font-weight: bold;
  }

  &__body {
    &--empty {
      padding: 16px;
      font-size: 16px;
    }
  }
}

.jp-value--d {
  padding-right: 5px;
}

.jp-row--d {
  margin-left: -7px;
}

.row2-item {
  &__top {
    padding: 8px 0px 2px 0px;
    text-align: center;
  }

  &__bottom {
    display: flex;
    justify-content: space-around;
    color: $body-color;

    & > div {
      padding: 2px 0 6px 0;
      width: calc(100% / 3);
      min-width: 45px;
    }

    &--td {
      font-size: 14px;
      color: $gray-500;
    }
  }
}

.extra-points {
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 5px;
}

.sub-points-1 {
  font-size: 15px;
}

.sub-points-2 {
  font-size: 14px;
}

.sub-points-3 {
  font-size: 13px;
  color: $gray-500;
}

.judge-points {
  &:first-child {
    margin-top: 3px;
  }

  .points {
    padding-left: 5px;
  }
}

.program-partial {
  color: $gray-500;
  &--mobile {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.rg-ae-program-partial-mob {
  padding: 0 10px 0 5px;
}
.rg-d-program-partial-mob {
  margin-left: -7px;
  margin-right: 4px;
}

.rg-apparatus-toggle {
  font-weight: 600;
  cursor: pointer;

  &--inactive {
    color: $gray-600;
  }
}

.results-akro {
  .participant-name {
    padding: 5px 0;
  }
}

.activated-row {
  background-color: #b4b4b4 !important;
}

.participant-details-card {
  padding: 16px;

  &__popover {
    @include media-breakpoint-up('sm') {
      width: 500px;
      min-width: 500px;
    }
  }

  h3 {
    margin-right: 10px;
  }

  h5 {
    margin-top: 8px;
    margin-bottom: 4px;
  }
}

.rg-results {
  &__apparatus-icon-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff80;
  }
}

.akro-mt-results {
  &__type-item {
    cursor: pointer;
    font-size: 18px;
    color: $gray-600;
    font-weight: 600;

    &--active {
      color: $gray-800;
    }
  }
}
