.licenses-component {
  .license-type,
  license-dates {
    width: 100px;
  }
  .license-sport {
    width: 50px;
  }

  .inactive-license {
    color: $gray-450;
    text-decoration: line-through;
  }

  .org-licenses {
    padding-left: 10px;
  }

  .div-table {
    .div-td.licenses-col {
      @include media-breakpoint-down('xs') {
        padding-top: 4px;
      }
    }
    .div-td.name-col,
    .div-td.dob-col {
      @include media-breakpoint-down('xs') {
        padding-bottom: 4px;
      }
    }
    .div-td.name-col {
      .name {
        @include media-breakpoint-down('xs') {
          font-weight: 700;
        }
      }
    }
  }

  .toggle-details-button {
    position: absolute;
    right: 10px;
    bottom: 2px;
  }
}
