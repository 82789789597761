.tg-start-list-editor {
  &__delete-button {
    margin: auto 10px auto 10px;
  }

  &__category-list-column {
    margin-top: auto;
    margin-bottom: auto;
  }

  &__settings-block {
    background-color: $gray-300;
    padding: 16px;
  }
  &__settings-block-button {
    background-color: $white;
  }

  &__duration-input {
    width: 175px;
  }

  &__club-name {
    color: $gray-500;
  }

  &__item_highlighted {
    &-1 {
      background-color: $highlightColor1;
    }
    &-2 {
      background-color: $highlightColor2;
    }
    &-3 {
      background-color: $highlightColor3;
    }
    &-4 {
      background-color: $highlightColor4;
    }
    &-5 {
      background-color: $highlightColor5;
    }
    &-6 {
      background-color: $highlightColor6;
    }
    &-7 {
      background-color: $highlightColor7;
    }
    &-8 {
      background-color: $highlightColor8;
    }
    &-9 {
      background-color: $highlightColor9;
    }
    &-10 {
      background-color: $highlightColor10;
    }
  }

  &__color-indicator {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__button {
    cursor: pointer;
    font-size: 15px;
    font-weight: 600;

    &_enabled {
      svg {
        path.content {
          fill: $gray-800;
        }
      }
      color: $gray-800;
    }
    &_disabled {
      svg {
        path.content {
          fill: $gray-450;
        }
      }
      color: $gray-450;
    }
  }
}

.tg-start-list-table {
  table-layout: fixed;

  &__swap-columns-button-container {
    position: relative;
    width: 0;
    height: 0;
  }
  &__swap-columns-button {
    display: none;

    svg {
      path.content {
        fill: $gray-600;
      }
    }
  }

  thead {
    th:hover {
      .tg-start-list-table__swap-columns-button {
        display: block;
      }
    }
  }

  &__time-column {
    width: 100px;
    &_editable {
      width: 130px;
    }

    @include media-breakpoint-down('sm') {
      width: 45px;
    }
  }
  &__prog-column {
    width: 33%;
  }
  &__table-height {
    color: $gray-500;
  }

  &__entry {
    cursor: pointer;

    &_cancelled {
      text-decoration: line-through;
    }

    &_selected {
      background-color: $highlightColor10;
    }
  }

  &__time-edit-buttons {
    z-index: 100;
    display: flex;
    background-color: $white;
    opacity: 90%;
    padding-right: 5px;
    padding-top: 4px;
    border-radius: 7px;
  }

  &__button {
    padding-left: 5px;
    cursor: pointer;

    &_enabled {
      svg {
        polygon {
          fill: $gray-800;
        }
      }
    }
    &_disabled {
      svg {
        polygon {
          fill: $gray-450;
        }
      }
    }
  }
  &__edit-time-button {
    display: none;
  }

  th {
    line-height: 24px; /*// make equal line height (forced by up-down arrow)*/
    @include media-breakpoint-down('sm') {
      padding: 10px 5px;
    }
  }

  &__row {
    td {
      line-height: 26px;
      padding-bottom: 14px;
      padding-top: 14px;
      @include media-breakpoint-down('sm') {
        padding: 7px 5px;
      }
    }
    &:hover {
      .tg-start-list-table__edit-time-button {
        display: inline;
      }
    }
  }
}

.tg-add-participant-form {
  &__error {
    font-size: 14px;
    color: $red;
  }
}
