.judge-terminal-points {
  &__points-col-header {
    width: 80px;
  }

  &__name-col-header {
    width: 240px;
  }

  &__name-col {
    font-size: 14px;
    padding: 4px 8px;
    line-height: 34px;
  }

  &__points-col {
    padding: 4px 8px;
    text-align: center;
    font-size: 14px;
    white-space: nowrap;
  }

  &__col-separator {
    border-right: solid 1px $gray-800;
  }

  &__deductions {
    padding-left: 1px;
    padding-right: 1px;
    color: $white;
    background-color: $gray-800;
    line-height: 16px;
    cursor: pointer;
    margin-top: 2px;
  }

  &__points {
    padding-right: 2px;

    &_diff {
      color: $red;
    }
  }

  &__akro-ded {
    font-size: 12px;
    color: $red;
  }

  &__bonus {
    padding-left: 2px;
    padding-right: 2px;

    &_normal {
      color: $green;
    }

    &_removed {
      color: $red;
      text-decoration: line-through;
    }

    &_added {
      color: $red;
    }
  }

  &__table {
    tbody {
      vertical-align: top;
    }
  }

  &__indicator {
    &_unsibmitted {
      height: 8px;
      width: 8px;
      background-color: $red;
      border-radius: 50%;
      margin-left: 1px;
    }

    &_submitted {
      position: absolute;
      right: 2px;
      top: -2px;
    }
  }

  &__legend {
    font-size: 12px;
  }
}

.jt-deductions-popover {
  width: 300px;
  min-width: 300px;

  &__title,
  &__body {
    padding: 10px;
  }
}

.modal-judge-terminal-points {
  max-width: 100%;
}
