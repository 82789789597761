.custom-checkbox {
  position: relative;

  label {
    padding-left: 40px;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 9px); /*// 9px - checkbox height*/
      left: 0;
      width: 18px;
      height: 18px;
      background-color: $gray-400;
      border-radius: 2px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.dark {
    label {
      &:before {
        background-color: $gray-450;
      }
    }
  }

  input:checked + label:before {
    background-color: $white;
    background-image: url('/content/images/checkmark.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  @include media-breakpoint-down('md') {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

.visually-hidden {
  position: absolute;

  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;

  clip: rect(0 0 0 0);
  overflow: hidden;
}
