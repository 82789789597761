.category-registrations {
  &__participant-row_waiting-list {
    background-color: $gray-450;
  }

  &__promote-button {
    &_disabled {
      svg {
        path.content {
          fill: $gray-450;
          stroke: $gray-450;
        }
      }
    }

    &_enabled {
      svg {
        path.content {
          fill: $gray-800;
          stroke: $gray-800;
        }
      }
    }
  }
}

.category-registrations-row {
  &__select-row-checkbox {
    label {
      padding: 0 20px 0 0;
    }
  }

  &__mag-team-icon {
    svg text {
      fill: $gray-800;
    }
  }
}
