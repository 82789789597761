.myclub {
  margin-top: 16px;
  padding-top: 0;
}

.club-regist {
  .nav-tabs {
    margin-right: -16px;
    margin-left: -16px;
    .nav-link {
      font-size: 18px;
    }
  }

  .tab-content {
    margin-top: 40px;
  }

  .card-section {
    border-left: 1px solid $gray-400;
  }

  .req-counter {
    width: 81px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background-color: $primary;
    font-weight: bold;
    font-size: 30px;
    a {
      color: $white;
    }
  }
}
