.card {
  box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.05);
  border: none;
  border-radius: 4px;
  overflow: hidden;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 0;
    padding: 16px;
    background-color: $gray-400;

    &:first-child {
      border-radius: 4px;
    }

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 120%;
      margin-bottom: 0;
    }
  }

  &-icon,
  &-icons {
    cursor: pointer;
  }

  &-icons {
    display: flex;
    align-items: center;

    .card-icon:not(:last-child) {
      margin-right: 16px;
    }
  }

  &-body {
    padding: 16px;
  }
}
