.m-t-1 {
  margin-top: 8px;
}

.m-t-2 {
  margin-top: 16px;
}

.m-t-3 {
  margin-top: 40px;
}

.m-r-1 {
  margin-right: 8px;
}

.m-r-2 {
  margin-right: 16px;
}

.m-b-1 {
  margin-bottom: 8px;
}

.m-b-2 {
  margin-bottom: 16px;
}

.m-l-1 {
  margin-left: 8px;
}

.m-l-2 {
  margin-left: 16px;
}

.mt-h5 {
  margin-top: 25px;
}
