.user_item {
  md-select {
    height: 20px;
    background: none;
  }

  md-autocomplete {
    height: 20px;
    background: none;
  }

  .label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 120%;
    padding-left: 40px;
    padding-top: 16px;
  }
  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 120%;
    padding-top: 16px;
  }

  .form-control {
    font-size: 14px;
  }
}

.user-list {
  &__info {
    background-color: #fff;
    padding: 20px;
    font-size: 16px;
  }
  &__info-buttons {
    .btn:not(:last-child) {
      margin-right: 8px;

      @include media-breakpoint-down('md') {
        margin-right: 0;
      }
    }
  }
}

#user-list {
  .input-w-icon__icon {
    right: 16px;
  }
}

.user_list {
  md-autocomplete {
    background: none;
  }

  md-input-container {
    margin: 0;
  }

  input[type='checkbox'] {
    all: none;
    display: inline-block;
    -webkit-appearance: checkbox;
    margin-right: 4px;
  }
  input[type='checkbox'] + label::before {
    display: none;
  }

  input[type='checkbox'] + label {
    font-size: 14px;
    display: inline;
  }

  &_row {
    align-items: center;
    background-color: $white;
    box-shadow: 0px 4px 20px rgba(35, 33, 61, 0.1);
    border-radius: 4px;
    overflow: hidden;
    line-height: 120%;
    min-height: 48px;
    margin-left: 0px;
    border: 1px solid $white;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &.selected,
    &:hover {
      border: 1px solid $blue;
    }
    cursor: pointer;
  }

  &_name {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
  }

  &_firm {
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }

  &_icon {
    display: flex;
    justify-content: flex-end;
  }
}

.redbox {
  border: 1px solid red;
}

.mobile-action-screen {
  .label {
    @include media-breakpoint-down('xs') {
      padding-left: 15px;
    }
  }

  .user-list__info-buttons {
    margin-right: 0;
    margin-left: 0;
  }

  .btn--lg {
    @include media-breakpoint-down('xs') {
      width: 100%;
      margin-bottom: 8px;
    }
  }

  .btn--md {
    @include media-breakpoint-down('xs') {
      flex-grow: 1;
      margin-right: 8px !important;
    }
  }

  .btn--goback {
    display: none;
    @include media-breakpoint-down('xs') {
      display: initial;
      margin-bottom: 20px;
    }
  }

  .col-md-12:not(:first-child) {
    @include media-breakpoint-down('xs') {
      margin-top: 16px;
    }
  }

  .user_list_name {
    @include media-breakpoint-down('xs') {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
