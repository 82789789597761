.manage-registrations {
  &__program-column {
    kn-start-list-entry-program-label > div {
      display: inline-block;
    }
  }

  table {
    table-layout: fixed;
    th {
      &.actions {
        width: 100px;
      }
      &.participant-name {
        width: 100%;
      }
      &.points,
      &.total-points {
        white-space: nowrap;
      }
      &.points {
        width: 150px;
      }
      &.total-points {
        width: 115px;
      }
    }
  }
}

.club-event-reg {
  &__item {
    padding: 16px;
    margin-bottom: 8px;
    align-items: center;

    h5 {
      margin: 0;

      a {
        color: $body-color;
      }
    }
  }

  &__registration-row {
    &_cancelled {
      text-decoration: line-through;
    }
    &_waiting-list {
      background-color: $gray-500;
    }
    &_processing {
      background-color: #fffd9f;
    }
  }

  .cancelled-registration-row {
    text-decoration: line-through;
  }
}

.event-register {
  &__member {
    margin-bottom: 16px;
  }

  .search-bar {
    margin-top: 15px;

    .checkbox-container {
      @include media-breakpoint-down('xs') {
        padding-top: 25px;
        padding-bottom: 10px;
      }
    }
  }

  #terms-accepted-cb {
    label {
      font-weight: 600;
      &:before {
        top: 10px;
      }
    }
  }
}

#registration-detail,
.registration-detail {
  margin-top: 30px;

  .panel-content {
    background: white;
    padding: 16px 16px 16px 16px;
  }
  h5 {
    margin-bottom: 0;
    margin-top: 16px;
    font-family: 'Rubik', Helvetica, Arial, sans-serif;
    font-weight: 800;
    font-size: 16px;
  }

  md-icon {
    color: white;
  }
  .music_btn {
    font-size: 24px;
    height: 24px;
    width: 24px;
    color: white;
  }

  .playback-progress {
    display: inline-block;
    vertical-align: middle;
  }

  #special-permission-cb {
    margin-top: 16px;
  }

  .registration-detail {
    &__status-label {
      padding: 8px;
      color: white;
      text-align: center;
      margin-right: 16px;
      border-radius: 3px;
      font-size: 16px;

      .time {
        font-size: 16px;
      }

      &_cancelled {
        background-color: $red;
      }
      &_waiting-list {
        background-color: $yellow;
        color: $gray-800;
      }
    }
  }

  .program-excluded {
    color: $gray-700;
  }

  .default-color {
    color: #000;
  }
}
