.app-settings-parent {
  padding-top: 0;

  &__nav {
    a.nav-link {
      text-transform: none;
    }
  }
}

.app-settings-content {
  margin-top: 20px;
}
