@import './styles';

ul {
  margin: 0;
  padding: 0px;
  border: 0;
  outline: 0;
  font-size: 100%;
  list-style: none;
}
